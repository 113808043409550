// note dummy fields (dF) - should replace with array not object
// unless need to have properties in this file at field level

const fieldLayout = {
  fields: {
    pageTitleField: 'fieldName',
    pageSingularNoun: 'field',
    pagePluralNoun: 'fields',
    0: {
      sectionTitle: 'Field Details',
      fields: {
        collection: { dF: 'Field Collection' },
        fieldName: { dF: 'Field Name' },
        fieldTitle: { dF: 'Field Title' },
        dataType: { dF: 'Data Type' },
        inputType: { dF: 'Input Type' },
        isRequired: { dF: 'Is Required?' },
      },
    },
  },
  mappings: {
    pageTitleField: 'mappingSourceField',
    pageSingularNoun: 'mapping',
    pagePluralNoun: 'mappings',
    0: {
      sectionTitle: 'Mapping Details',
      fields: {
        mappingSourceSystem: { dF: 'Source System' },
        mappingSourceCollection: { dF: 'Source Collection' },
        mappingSourceIDField: { dF: 'Source ID Field' },
        mappingSourceField: { dF: 'Source Data Field' },
        mappingQtCollection: { dF: 'QuikTools Collection' },
        mappingQtFieldName: { dF: 'QuikTools Field Name' },
      },
    },
  },
  organisations: {
    pageTitleField: 'orgName',
    pageSingularNoun: 'organisation',
    pagePluralNoun: 'organisations',
    0: {
      sectionTitle: 'Organisation Details',
      fields: {
        orgName: { dF: 'Contact Name' },
        orgBillingStreet: { dF: 'Street' },
        orgBillingCity: { dF: 'City' },
        orgBillingPostCode: { dF: 'City' },
        orgBillingState: { dF: 'State' },
        orgBillingCountry: { dF: 'Country' },
      },
    },
  },
  users: {
    pageTitleField: 'userNameFirst',
    pageSingularNoun: 'user',
    pagePluralNoun: 'users',
    0: {
      sectionTitle: 'User Details',
      fields: {
        userNameFirst: { dF: 'userNameFirst' },
        userNameLast: { dF: 'userNameLast' },
        userEmail: { dF: 'userEmail' },
      },
    },
  },
  bridges: {
    pageTitleField: 'bridgeName',
    pageSingularNoun: 'bridge',
    pagePluralNoun: 'bridges',
    0: {
      sectionTitle: 'Basic Details',
      fields: {
        bridgeName: { dF: '' },
        bridgeOrganisation: { df: 'Organisation Reference' },
        bridgeClassification: { df: 'B1 / B2' },
      },
    },
    1: {
      sectionTitle: 'Location',
      fields: {
        bridgeAddressStreet: { dF: 'Street' },
        bridgeAddressTown: { dF: 'Town' },
        bridgeAddressState: { dF: 'State' },
        bridgeAddressPostCode: { dF: 'Post Code' },
        // bridgeAddressCoordinates: { dF: 'Coordinates' },
      },
    },
    2: {
      sectionTitle: 'Delivery',
      fields: {
        bridgeDeliveryDate: { dF: 'Est. Date' },
      },
    },
    3: {
      sectionTitle: 'Deck',
      fields: {
        bridgeDesign: { dF: 'Design' },
        bridgeSpanCount: { dF: 'Span Count' },
        bridgeSpanLength: { dF: 'Span Length' },
        bridgeDeckWidthTotal: { dF: 'Total Deck Width' },
        bridgeSideformType: { dF: 'Side Form Type' },
        bridgeAbutmentHeight: { dF: 'Abutment Height' },
        bridgeBarrierType: { dF: 'Barrier Type' },
        bridgeFormworkMaterial: { dF: 'Material' },
      },
    },
    4: {
      sectionTitle: 'Abutments',
      fields: {
        bridgeCamber: { dF: 'Camber / Slope' },
        bridgeAbutmentInterface: { dF: 'Abut. Interface' },
        bridgeAbutmentThickness: { dF: 'Abut. Thickness' },
        bridgeTieDowns: { dF: 'Tie Downs' },
        bridgeApproachSlab: { dF: 'Approach Slab' },
        bridgeWingWallAngle: { dF: 'Wing Wall Angle' },
        bridgeWingWallLength: { dF: 'Wing Wall Length' },
      },
    },
    5: {
      sectionTitle: 'Comments',
      fields: {
        bridgeDesignComments: { dF: '' },
      },
    },
  },
  rfqs: {
    pageTitleField: 'rfqTitle',
    pageSingularNoun: 'rfq',
    pagePluralNoun: 'rfqs',
    0: {
      sectionTitle: 'Quotation',
      fields: {
        rfqTitle: { dF: '' },
        rfqTemplate: { dF: '' },
        rfqOwner: { dF: '' },
      },
    },
    1: {
      sectionTitle: 'Customer',
      fields: {
        rfqOrganisation: { df: 'Organisation Reference' },
        rfqContactName: { dF: '' },
        rfqContactEmail: { dF: '' },
        rfqContactPhone: { dF: '' },
      },
    },
    2: {
      sectionTitle: 'Bridge',
      fields: {
        rfqBridge: { dF: 'Bridge Reference' },
      },
    },
  },
  costings: {
    pageTitleField: 'bridgeName',
    pageSingularNoun: 'costing',
    pagePluralNoun: 'costings',
    0: {
      sectionTitle: 'Costing Information',
      fields: {
        quoteDescription_text: { dF: '' },
        quoteTotalPrice_value: { dF: '' },
      },
    },
  },
};

export default fieldLayout;
