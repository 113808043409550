// react
import React, { useState, useContext } from 'react';

// gatsby
import { navigate } from 'gatsby';

// material UI
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';

// material UI icons
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NotVerifiedUserIcon from '@material-ui/icons/HighlightOff';
import VerifiedAdminIcon from '@material-ui/icons/VerifiedUser';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

// page-specific includes
import GoogleLogo from './GoogleLogo';

// shared includes
import {
  signInWithGoogle,
  isUserLoggedIn,
  getFirebase,
} from '../utils/firebase';
import { UserContext } from '../../providers/UserProvider';

const SignIn = (props) => {
  const [error, setError] = useState(null);
  const contextObject = useContext(UserContext);
  const userObject = contextObject.user;

  if (props.error) {
    setError(props.error);
  }

  let dialogBody = <></>;
  if (isUserLoggedIn && userObject !== null) {
    dialogBody = <UserProfileDialog user={userObject} error={error} />;
  } else {
    dialogBody = <SignInDialog error={error} />;
  }

  return (
    <Container className="qtblack" maxWidth="sm">
      <Paper style={{ padding: '20px' }} elevation={2}>
        {dialogBody}
      </Paper>
    </Container>
  );
};
export default SignIn;

const SignInDialog = (props) => {
  return (
    <Box>
      <Typography variant="h5" component="h1" style={{ textAlign: 'center' }}>
        Sign In
      </Typography>
      {props.error !== null && (
        <Typography
          variant="body1"
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          {props.error}
        </Typography>
      )}
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        A wise message about only logging on if you&apos;re authorised and all
        that jazz.
      </Typography>
      <Box style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="default"
          size="medium"
          startIcon={<GoogleLogo />}
          onClick={() => {
            signInWithGoogle();
          }}
          style={{ marginTop: '20px' }}
        >
          Sign in with Google
        </Button>
      </Box>
    </Box>
  );
};

const UserProfileDialog = (props) => {
  // auth object is just for the sign-out link - central function in firebase.js would make sense
  let auth = null;
  const contextObject = useContext(UserContext);
  const { isFetchingProfile } = contextObject;
  const { isVerifiedUser } = contextObject;
  const { isVerifiedAdmin } = contextObject;

  if (typeof window !== 'undefined') {
    const firebase = getFirebase();

    if (firebase !== null) {
      auth = firebase.auth();
    } else {
      console.log('UserProfileDialog: Firebase was null');
    }
  }

  return (
    <Box>
      {props.error !== null && (
        <Typography
          variant="body1"
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          {props.error}
        </Typography>
      )}
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                <Avatar
                  alt={`${props.user.displayName} Image`}
                  src={props.user.photoURL}
                  style={{
                    width: '50px',
                    height: '50px',
                    display: 'inline-block',
                    border: '1px solid gray',
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Status</Typography>
              </TableCell>
              <TableCell
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                <UserVerifiedStatus
                  isVerifiedUser={isVerifiedUser}
                  isFetchingProfile={isFetchingProfile}
                />
              </TableCell>
            </TableRow>
            <AdminVerifiedRow
              isVerifiedAdmin={isVerifiedAdmin}
              isFetchingProfile={isFetchingProfile}
            />
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'left',
                  }}
                >
                  {props.user.displayName}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Email</Typography>
              </TableCell>
              <TableCell
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'left',
                  }}
                >
                  {props.user.email}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="default"
          size="medium"
          startIcon={<ExitToAppIcon />}
          onClick={() => {
            handleSignOut();
          }}
          style={{ marginTop: '20px' }}
        >
          Sign Out
        </Button>
      </Box>
    </Box>
  );

  function handleSignOut() {
    sessionStorage.clear();
    auth.signOut();
    navigate('/home/');
  }
};

const UserVerifiedStatus = (props) => {
  if (props.isFetchingProfile) {
    return (
      <Typography variant="body1">
        <span style={{ color: 'lightgray' }}>
          Retrieving security profile...
        </span>
      </Typography>
    );
  }
  if (props.isVerifiedUser) {
    return (
      <Typography variant="body1">
        <VerifiedUserIcon
          fontSize="small"
          style={{ fill: 'green', verticalAlign: 'text-bottom' }}
        />
        &nbsp;
        <b>Verified</b>
      </Typography>
    );
  }
  return (
    <Typography variant="body1">
      <NotVerifiedUserIcon
        fontSize="small"
        style={{ fill: 'orange', verticalAlign: 'text-bottom' }}
      />
      &nbsp;
      <b>Not Verified</b>
    </Typography>
  );
};

const AdminVerifiedRow = (props) => {
  if (props.isFetchingProfile) {
    return null;
  }
  if (props.isVerifiedAdmin) {
    return (
      <TableRow>
        <TableCell>
          <Typography variant="subtitle1">Admin</Typography>
        </TableCell>
        <TableCell
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
          }}
        >
          <Typography
            variant="body1"
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'left',
            }}
          >
            <VerifiedAdminIcon
              fontSize="small"
              style={{ fill: 'green', verticalAlign: 'text-bottom' }}
            />
            &nbsp;<b>Super Admin</b>
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return null;
};
