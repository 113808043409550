// React
// eslint-disable-next-line
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function QTDialog(props) {
  const {
    open,
    dialogTitle,
    dialogBody,
    dialogTarget,
    handleClose,
    handleCloseOk,
    handleCloseCancel,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={dialogTitle}
      aria-describedby={dialogTitle}
      className="qtblack"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogBody}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleCloseOk(dialogTarget);
          }}
          value={`${dialogTarget}Ok`}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
