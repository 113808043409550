// eslint-disable-next-line no-use-before-define
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const QtTextField = withStyles({
  root: {
    width: '100%',
    minWidth: '250px',
    marginBottom: '8px',
    marginTop: '8px',
    '& input:valid + fieldset': {
      borderRightColor: 'green',
      borderRightWidth: 6,
      borderWidth: 1,
    },
    '& input:invalid + fieldset': {
      borderRightColor: 'red',
      borderRightWidth: 6,
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderRightWidth: 6,
      borderWidth: 1,
    },
  },
})(TextField);

export default QtTextField;
