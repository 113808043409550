// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UserContext } from '../providers/UserProvider'; // 'global' user object
import { getCollectionList } from './utils/dataFunctions'; // API data functions

const QTAutoCompleteControl = withStyles({
  root: {
    width: '100%',
    minWidth: '250px',
    marginBottom: '8px',
    marginTop: '0px',
  },
  listbox: {
    backgroundColor: 'white',
    '& li': {
      color: 'hsl(0, 0%, 16%)',
    },
  },
})(Autocomplete);

function QTAutoComplete(props) {
  const {
    searchCollection,
    searchDisplayField,
    parentKey,
    fieldName,
    fieldTitle,
    handleItemChange,
    isRequired,
    defaultValue,
  } = props;

  const contextObject = useContext(UserContext);
  const { firebaseToken } = contextObject;

  const collectionName = searchCollection;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const dropDownData = await getCollectionList(
        collectionName,
        firebaseToken, // from user context
      );

      let optionsArray = [];
      if (active) {
        const itemKeys = Object.keys(dropDownData.collectionItems);

        let itemIndex = 0;
        for (itemIndex = 0; itemIndex < itemKeys.length; itemIndex += 1) {
          optionsArray.push({
            ...dropDownData.collectionItems[itemKeys[itemIndex]],
            collectionName,
          });
        }

        // sorts the dropdown list by the display field
        optionsArray = optionsArray.sort((a, b) =>
          a[searchDisplayField] > b[searchDisplayField] ? 1 : -1,
        );

        setOptions(optionsArray);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, collectionName, searchDisplayField, firebaseToken]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <QTAutoCompleteControl
      id={`sel${parentKey}${fieldName}`}
      key={`sel${parentKey}${fieldName}`}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      name={fieldName}
      value={defaultValue}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.[searchDisplayField] ?? ''}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);

        const proxyEvent = {
          target: { name: fieldName, value: { ...newValue } },
        };

        handleItemChange(proxyEvent);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={fieldTitle}
          variant="outlined"
          margin="dense"
          required={isRequired}
          className="qtblack"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default QTAutoComplete;
