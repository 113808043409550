// 3rd party
// eslint-disable-next-line
import React, { useContext, useCallback } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AppBar from '@material-ui/core/AppBar';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeStyles, useTheme } from '@material-ui/core/styles';

// 1st party components
import DataSection from '../DataSection';
import PricingSection from '../../PricingSection';
import RFQDetail from '../../RFQ/RFQDetail';
import { UserContext } from '../../../providers/UserProvider'; // 'global' user object

// 1st party functions
import { putSingleRecord } from '../../utils/dataFunctions'; // API data functions
import { logMessage, flattenObject } from '../../utils/generalUtils';

// 1st party definitions
import fieldLayout from '../../fieldLayout';

/**
 * Helper functions
 */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

/**
 * Main ItemDetail component starts here
 */

export default function ItemDetail(props) {
  const userObject = useContext(UserContext);
  const [partsTable, setPartsTable] = React.useState([]);
  const [productFields, setProductFields] = React.useState([]);
  const [colTotals, setColTotals] = React.useState({
    totalCost: 0,
    totalPrice: 0,
    totalMarkupValue: 0,
    totalMarkupPercent: 0.0,
    totalConcrete: 0.0,
    totalWeight: 0.0,
  });

  const theme = useTheme();
  const classes = useStyles(theme);
  const { fieldMap } = props;
  const { isFetching } = props;
  const { currentItem } = props;
  const { currentItemId } = props;
  const { handleItemChange } = props;
  const { modifiedItems } = props;
  const { collectionName } = props;

  let pageTitle = '';

  let theSection = [];

  const displaySections = [];
  const currentFieldLayout = fieldLayout[collectionName];

  if (
    typeof currentItem === 'object' &&
    typeof fieldLayout[collectionName] === 'object'
  ) {
    // Set Page Title

    if (
      typeof fieldLayout[collectionName].pageTitleField === 'string' &&
      typeof currentItem[fieldLayout[collectionName].pageTitleField] ===
        'string' &&
      currentItem[fieldLayout[collectionName].pageTitleField] !== ''
    ) {
      pageTitle = currentItem[fieldLayout[collectionName].pageTitleField];
    } else if (
      currentItemId === 'new' &&
      typeof fieldLayout[collectionName].pageSingularNoun === 'string'
    ) {
      pageTitle = `New ${fieldLayout[collectionName].pageSingularNoun}`;
    } else {
      pageTitle = 'Title undefined.';
    }

    const layoutKeys = Object.keys(currentFieldLayout).sort();
    for (
      let layoutIndex = 0;
      layoutIndex < layoutKeys.length;
      layoutIndex += 1
    ) {
      const currentLayoutSectionKey = layoutKeys[layoutIndex];
      if (
        typeof currentFieldLayout[currentLayoutSectionKey] === 'object' &&
        typeof currentFieldLayout[currentLayoutSectionKey].fields === 'object'
      ) {
        displaySections.push(
          <DataSection
            key={`ds${currentItemId}.${currentLayoutSectionKey}`}
            parentKey={currentItemId + currentLayoutSectionKey}
            sectionKey={currentLayoutSectionKey}
            sectionData={currentItem}
            fieldMap={fieldMap}
            isFetching={isFetching}
            modifiedItems={modifiedItems}
            fieldLayout={currentFieldLayout}
            handleItemChange={handleItemChange}
          />,
        );
      }
    }

    // THIS IS WHERE EVERYTHING AFTER THE STANDARD ITEM DETAIL SECTION GOES

    if (
      collectionName === 'rfqs' &&
      typeof userObject?.isVerifiedAdmin !== 'undefined' &&
      userObject.isVerifiedAdmin &&
      typeof currentItem?.rfqBridge?.id !== 'undefined'
    ) {
      displaySections.push(
        <RFQDetail
          fieldMap={fieldMap}
          isFetching={isFetching}
          currentItem={currentItem}
          currentItemId={currentItemId}
          handleItemChange={handleItemChange}
          modifiedItems={modifiedItems}
          collectionName={collectionName}
        />,
      );
    } else {
      displaySections.push(
        <div key="newstuffnotRFQ">
          DEBUG ItemDetail: To confirm, we are NOT doing an RFQ.
        </div>,
      );
    }

    theSection = <div key={`${currentItemId}.section`}>{displaySections}</div>;
  }
  return (
    <>
      <Paper className={classes.root} elevation={2}>
        <AppBar
          className="qtblack"
          position="static"
          color="default"
          elevation={1}
          style={{ borderRadius: '4px 4px 0px 0px' }}
        >
          <Box
            style={{
              margin: '10px 10px 0px 10px',
              textAlign: 'center',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
          >
            <Typography
              variant="subtitle1"
              component="h1"
              style={{
                fontWeight: 'bold',
                paddingBottom: '8px',
                fontSize: '1.2rem',
              }}
            >
              <>{pageTitle}</>
            </Typography>
          </Box>
        </AppBar>
        <div className="qtblack" key="mainsection">
          {theSection}
        </div>
      </Paper>
    </>
  );
}
