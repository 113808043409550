// 3rd party
// eslint-disable-next-line
import React, { useContext, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { makeStyles, useTheme } from '@material-ui/core/styles';

// 1st party components
import PricingSection from '../PricingSection';
import { UserContext } from '../../providers/UserProvider'; // 'global' user object

// 1st party functions
import { putSingleRecord } from '../utils/dataFunctions'; // API data functions
import { logMessage, flattenObject } from '../utils/generalUtils';

// 1st party definitions
import fieldLayout from '../fieldLayout';

/**
 * Helper functions
 */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const productTableDefinition = {
  columns: [
    'col_partDescription',
    'col_partQuantity',
    'col_partCost',
    'col_markup',
    'partTotalCost',
    'partMarkup',
    'partTotalPrice',
    'partTotalConcrete',
    'partTotalWeight',
  ],
  columnDefinitions: {
    col_partDescription: {
      totalColumn: false,
      valueType: 'string',
      align: 'left',
      description: 'Part',
      editable: false,
    },
    col_partQuantity: {
      totalColumn: false,
      valueType: 'integer',
      align: 'right',
      description: 'Qty',
      editable: false,
    },
    col_partCost: {
      totalColumn: false,
      valueType: 'currency',
      align: 'right',
      description: 'Cost (each)',
      editable: false,
    },
    col_partWeight: {
      totalColumn: true,
      valueType: 'tonnage',
      align: 'right',
      displayUnit: <i>t</i>,
      description: 'Weight (t)',
      editable: false,
    },
    col_partConcrete: {
      totalColumn: true,
      valueType: 'volume',
      align: 'right',
      displayUnit: <i>m&sup3;</i>,
      description: <>Concrete (m&sup3;)</>,
      editable: false,
    },
    col_markup: {
      totalColumn: true,
      valueType: 'percentage',
      align: 'right',
      description: 'Part Markup %',
      editable: true,
      totalField: 'totalMarkupPercent',
    },
    // calculated fields
    partTotalWeight: {
      totalColumn: true,
      valueType: 'tonnage',
      align: 'right',
      displayUnit: <i>t</i>,
      description: 'Part Weight Total (t)',
      editable: false,
      totalField: 'totalWeight',
    },
    partTotalConcrete: {
      totalColumn: true,
      valueType: 'volume',
      align: 'right',
      displayUnit: <i>m&sup3;</i>,
      description: <>Part Concrete Total (m&sup3;)</>,
      editable: false,
      totalField: 'totalConcrete',
    },
    partTotalCost: {
      totalColumn: true,
      calculatedColumn: true,
      valueType: 'currency',
      align: 'right',
      description: 'Part Cost Total ($)',
      editable: false,
      totalField: 'totalCost',
    },
    partMarkup: {
      totalColumn: true,
      valueType: 'currency',
      align: 'right',
      description: 'Part Markup Total ($)',
      editable: false,
      totalField: 'totalMarkupValue',
    },
    partTotalPrice: {
      totalColumn: true,
      valueType: 'currency',
      align: 'right',
      description: 'Part Price Total ($)',
      editable: false,
      totalField: 'totalPrice',
    },
  },
};

// adds calculated field to data array
function addCalculatedField(
  originalObject,
  fieldName,
  fieldEditable,
  fieldValue,
  fieldValueType,
  fieldKey,
  fieldText = '',
) {
  let objectWithNewFieldAdded = {};

  const newField = {};
  newField[fieldName] = {
    editable: fieldEditable,
    fieldHash: fieldKey,
    text: fieldText,
    value: fieldValue,
    valueType: fieldValueType,
  };

  objectWithNewFieldAdded = { ...originalObject, ...newField };
  return objectWithNewFieldAdded;
}

function QuoteButton(quoteButtonProps) {
  const { variant, color, children, objectToPut, firebaseToken, size } =
    quoteButtonProps;

  const templateName = 'budgetestimate';

  async function getQuotePDF() {
    // set quote

    await putSingleRecord(
      'quotations',
      `${'generatePDF/'}${templateName}`,
      objectToPut,
      firebaseToken,
      null,
      'blob',
    );
  }

  return (
    <Button variant={variant} color={color} onClick={getQuotePDF} size={size}>
      {children}
    </Button>
  );
}

export default function RFQDetail(props) {
  const userObject = useContext(UserContext);
  const [detailsRetrieved, setDetailsRetrieved] = React.useState(0);
  const [partsTable, setPartsTable] = React.useState([]);
  const [productFields, setProductFields] = React.useState([]);
  const [colTotals, setColTotals] = React.useState({
    totalCost: 0,
    totalPrice: 0,
    totalMarkupValue: 0,
    totalMarkupPercent: 0.0,
    totalConcrete: 0.0,
    totalWeight: 0.0,
  });

  const theme = useTheme();
  const classes = useStyles(theme);
  const { fieldMap } = props;
  const { isFetching } = props;
  const { currentItem } = props;
  const { currentItemId } = props;
  const { handleItemChange } = props;
  const { modifiedItems } = props;
  const { collectionName } = props;

  const pageTitle = '';

  let theSection = [];

  const displaySections = [];
  const currentFieldLayout = fieldLayout[collectionName];

  const getProductDetail = React.useCallback(
    async (theCollection, theId, theObjectValue) => {
      if (
        userObject.isVerifiedAdmin &&
        typeof userObject.firebaseToken === 'string'
      ) {
        try {
          logMessage(
            'putSingleItem',
            `Putting item ${theId} in collection`,
            theCollection,
            'AdminSection.jsx',
          );

          const returnObject = await putSingleRecord(
            theCollection,
            theId,
            theObjectValue,
            userObject.firebaseToken,
          );

          logMessage(
            'putSingleItem',
            'Returned Object',
            returnObject,
            'AdminSection.jsx',
            'ERROR',
          );

          return returnObject;
        } catch (e) {
          logMessage(
            'putSingleItem',
            'Unhandled Error',
            e,
            'AdminSection.jsx',
            'ERROR',
          );
          return {};
        }
      }
      return {};
    },
    [userObject.firebaseToken, userObject.isVerifiedAdmin],
  );

  function DetailsButton(quoteButtonProps) {
    const { variant, color, children, size } = quoteButtonProps;

    async function getQuoteDetails() {
      // set quote

      console.log('Getting Quote Details');

      getProductDetail('costings', currentItem.rfqBridge.id, {}).then(
        (costingDetails) => {
          const partsTableObject =
            costingDetails.message?.outputRanges?.partsTable;
          const outputFieldsObject = costingDetails.message?.outputFields;

          // add markup column using default markup
          if (
            typeof partsTableObject?.rows !== 'undefined' &&
            partsTableObject?.rows.length > 0
          ) {
            for (let i = 0; i < partsTableObject.rows.length; i += 1) {
              if (
                typeof partsTableObject?.rows[i] !== 'undefined' &&
                typeof partsTableObject?.rows[i].col_markup !== 'undefined'
              ) {
                partsTableObject.rows[i].col_markup.editable = true;
                partsTableObject.rows[i].col_markup.valueType = 'percent';
              }
            }
          }

          setPartsTable(partsTableObject);
          setProductFields(outputFieldsObject);
          setDetailsRetrieved(true);
        },
      );
    }

    return (
      <Button
        variant={variant}
        color={color}
        onClick={getQuoteDetails}
        size={size}
      >
        {children}
      </Button>
    );
  }
  // getting product details one time
  /* WIP: REMOVING PRODUCT COSTINGS FOR NOW
    React.useEffect(() => {
      if (
        collectionName === 'rfqs' &&
        typeof userObject?.isVerifiedAdmin !== 'undefined' &&
        userObject.isVerifiedAdmin &&
        typeof currentItem?.rfqBridge?.id !== 'undefined'
      ) {
        getProductDetail('costings', currentItem.rfqBridge.id, {}).then(
          (costingDetails) => {
            const partsTableObject =
              costingDetails.message?.outputRanges?.partsTable;
            const outputFieldsObject = costingDetails.message?.outputFields;

            // add markup column using default markup
            if (
              typeof partsTableObject?.rows !== 'undefined' &&
              partsTableObject?.rows.length > 0
            ) {
              for (let i = 0; i < partsTableObject.rows.length; i += 1) {
                if (
                  typeof partsTableObject?.rows[i] !== 'undefined' &&
                  typeof partsTableObject?.rows[i].col_markup !== 'undefined'
                ) {
                  partsTableObject.rows[i].col_markup.editable = true;
                  partsTableObject.rows[i].col_markup.valueType = 'percent';
                }
              }
            }

            setPartsTable(partsTableObject);
            setProductFields(outputFieldsObject);
          },
        );
      }
    }, [
      currentItem?.rfqBridge?.id,
      collectionName,
      userObject.isVerifiedAdmin,
      getProductDetail, // Remote Data function
      setPartsTable, // State function
      setProductFields, // State function
    ]);
    */

  console.log('WE ARE DOING AN RFQ!!!!!!!!');

  console.log('Props:', props);
  console.log('Collection Name:', collectionName);

  if (
    collectionName === 'rfqs' &&
    typeof userObject?.isVerifiedAdmin !== 'undefined' &&
    userObject.isVerifiedAdmin &&
    typeof currentItem?.rfqBridge?.id !== 'undefined'
  ) {
    displaySections.push(
      <Box
        style={{ width: '100%', padding: '10px 10px 0px 10px' }}
        key="rfqdetailbox"
      >
        <Box style={{ width: '100%' }} key="rfqdetailbox2">
          <Typography
            style={{
              color: '#151A30',
              fontSize: '1.1em',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            key="rfqdetailt1"
          >
            RFQ Detail
          </Typography>
        </Box>
      </Box>,
    );
    displaySections.push(
      <div
        key="rfqdetailsbuttondiv"
        style={{ paddingTop: '10px', paddingLeft: '10px' }}
      >
        <DetailsButton
          key="rfqdetailsbutton"
          variant="contained"
          color="default"
          firebaseToken={userObject.firebaseToken}
        >
          Calculate RFQ Details
        </DetailsButton>
      </div>,
    );
  }

  if (typeof currentItem?.rfqBridge?.id !== 'undefined') {
    if (
      collectionName === 'rfqs' &&
      typeof userObject?.isVerifiedAdmin !== 'undefined' &&
      userObject.isVerifiedAdmin &&
      typeof currentItem?.rfqBridge?.id !== 'undefined' &&
      detailsRetrieved === true
    ) {
      const tmpColTotals = {
        totalCost: 0,
        totalPrice: 0,
        totalMarkupValue: 0,
        totalMarkupPercent: 0.0,
        totalConcrete: 0.0,
        totalWeight: 0.0,
      };
      const fullPartsTable = { ...partsTable };
      if (
        typeof partsTable?.rows !== 'undefined' &&
        typeof partsTable?.headers !== 'undefined'
      ) {
        const rowsData = partsTable.rows;
        const rowsDataKeys = Object.keys(rowsData);

        for (
          let rowsDataIndex = 0;
          rowsDataIndex < rowsDataKeys.length;
          rowsDataIndex += 1
        ) {
          const currentRowKey = rowsDataKeys[rowsDataIndex];
          const currentRow = { ...rowsData[currentRowKey] };

          // CALCULATED FIELDS
          // Calculated client-side so they can be updated live

          let markUpPercent = 0;

          if (typeof currentRow.col_markup !== 'undefined') {
            markUpPercent = currentRow.col_markup.value;
          }

          const partCost = currentRow.col_partCost.value;
          const partPrice = partCost * (1 + markUpPercent);
          const partQuantity = currentRow.col_partQuantity.value;

          const partTotalCost =
            currentRow.col_partCost.value * currentRow.col_partQuantity.value;
          const partTotalConcrete =
            currentRow.col_partConcrete.value *
            currentRow.col_partQuantity.value;
          const partTotalWeight =
            currentRow.col_partWeight.value * currentRow.col_partQuantity.value;
          const partTotalPrice = partPrice * partQuantity;

          const partMarkup = partTotalPrice - partTotalCost;

          tmpColTotals.totalCost += Number(partTotalCost);
          tmpColTotals.totalConcrete += Number(partTotalConcrete);
          tmpColTotals.totalWeight += Number(partTotalWeight);
          tmpColTotals.totalPrice += Number(partTotalPrice);
          tmpColTotals.totalMarkupValue += Number(partMarkup);
          tmpColTotals.totalMarkupPercent = Math.round(
            (tmpColTotals.totalPrice / tmpColTotals.totalCost - 1) * 100,
          );

          let updatedRow = { ...currentRow };
          updatedRow = addCalculatedField(
            updatedRow,
            'partTotalCost',
            false,
            partTotalCost,
            'currency',
            'keyhere',
            '',
          );
          updatedRow = addCalculatedField(
            updatedRow,
            'partPrice',
            false,
            partPrice,
            'currency',
            'keyhere2',
            '',
          );
          updatedRow = addCalculatedField(
            updatedRow,
            'partTotalPrice',
            false,
            partTotalPrice,
            'currency',
            'keyhere3',
            '',
          );
          updatedRow = addCalculatedField(
            updatedRow,
            'partTotalConcrete',
            false,
            partTotalConcrete,
            'currency',
            'keyhere3',
            '',
          );
          updatedRow = addCalculatedField(
            updatedRow,
            'partTotalWeight',
            false,
            partTotalWeight,
            'currency',
            'keyhere3',
            '',
          );
          updatedRow = addCalculatedField(
            updatedRow,
            'partMarkup',
            false,
            partMarkup,
            'currency',
            'keyhere3',
            '',
          );
          fullPartsTable.rows[currentRowKey] = { ...updatedRow };
          // console.log(`r${rowsDataIndex}`, currentRow);

          // END CALCULATED FIELDS
          for (
            let colIndex = 0;
            colIndex < productTableDefinition.columns.length;
            colIndex += 1
          ) {
            const currentColumnKey = productTableDefinition.columns[colIndex];
            const currentColumnDefinition =
              productTableDefinition.columnDefinitions[currentColumnKey];
            const currentFieldType = currentColumnDefinition.valueType;

            let fieldValue;
            if (typeof currentRow[currentColumnKey] !== 'undefined') {
              if (currentFieldType === 'string') {
                fieldValue = currentRow[currentColumnKey].text;
              } else if (
                typeof currentRow[currentColumnKey]?.value !== 'undefined'
              ) {
                fieldValue = currentRow[currentColumnKey].value;
              } else {
                fieldValue = currentRow[currentColumnKey].text;
              }
            }

            if (!Number.isNaN(Number(fieldValue))) {
              if (typeof tmpColTotals[currentColumnKey] !== 'undefined') {
                tmpColTotals[currentColumnKey] += Number(fieldValue);
              } else {
                tmpColTotals[currentColumnKey] = Number(fieldValue);
              }
            }
          }
        }

        if (JSON.stringify(tmpColTotals) !== JSON.stringify(colTotals)) {
          setColTotals(tmpColTotals);
        }

        displaySections.push(
          <div
            className="MuiBox-root MuiBox-root-50"
            style={{
              textAlign: 'left',
              color: 'black',
              fontSize: '1.5em',
              lineHeight: '1.2em',
              paddingBottom: '10px',
              paddingTop: '20px',
              paddingLeft: '10px',
              fontWeight: 'bold',
            }}
          >
            Product & Pricing Details
          </div>,
        );

        displaySections.push(
          <PricingSection
            productTableDefinition={productTableDefinition}
            partsTable={partsTable}
            colTotals={colTotals}
            setPartsTable={setPartsTable}
            firebaseToken={userObject.firebaseToken}
          />,
        );

        // variant, color, children, objectToPut, localFBToken, size

        const fieldsForPDF = {};

        // this hard coded stuff has to go
        const flatItem = flattenObject(currentItem);

        const pfKeys = Object.keys(productFields);
        for (let pFI = 0; pFI < pfKeys.length; pFI += 1) {
          const cPFieldKey = pfKeys[pFI];
          let cPFieldValue = '';
          if (typeof productFields[cPFieldKey]?.text !== 'undefined') {
            cPFieldValue = productFields[cPFieldKey].text;
          }

          fieldsForPDF[cPFieldKey] = cPFieldValue;
        }

        const objectToPut = {
          fields: { ...fieldsForPDF, ...flatItem },
          productDetails: {
            partsTable: { ...fullPartsTable },
            colTotals: { ...colTotals },
            tableDefinition: { ...productTableDefinition },
          },
        };
        console.log(objectToPut);
        displaySections.push(
          <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
            <QuoteButton
              variant="contained"
              color="default"
              objectToPut={objectToPut}
              firebaseToken={userObject.firebaseToken}
            >
              Generate PDF
            </QuoteButton>
          </div>,
        );
      }
    }
  }

  theSection = <div key="rfqdetailsection.section">{displaySections}</div>;

  return (
    <>
      <div className="qtblack" key="rfqdetailsection">
        {theSection}
      </div>
    </>
  );
}
