// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import { navigate } from 'gatsby';

import { UserContext } from '../providers/UserProvider';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const contextObject = useContext(UserContext);

  let isUserLoggedIn = false;
  if (contextObject.isVerifiedUser) {
    isUserLoggedIn = true;
  } else {
    isUserLoggedIn = false;
  }

  if (
    !isUserLoggedIn &&
    location.pathname !== `/home` &&
    location.pathname !== `/app/signin`
  ) {
    // If we’re not logged in or not authorised, redirect to the login / profile page.

    navigate(`/app/signin`, { replace: true });
    contextObject.refreshToken();
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest} />;
};

export default PrivateRoute;
