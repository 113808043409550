// 3rd party
//    React
//    eslint-disable-next-line
import React, { useState, useEffect } from 'react';

//    material UI
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
// import Fade from '@material-ui/core/Fade';

import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SyncIcon from '@material-ui/icons/Sync';

// 1st party
import { isEmptyObject } from '../../utils/generalUtils';

// making the data grid look right
const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: 'rgb(245 245 245)',
      borderRadius: '4px 4px 0px 0px',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    '& .MuiDataGrid-colCellTitle': {
      borderRadius: '4px 4px 0px 0px',
      color: 'rgba(0, 0, 0, 0.54);',
      textTransform: 'uppercase',
      fontSize: '0.875rem',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      borderRight: 'none',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-footer': {
      height: '30px',
      minHeight: 'auto',
    },
  },
}));

/** ***
 * Main Component starts here
 */

export default function CollectionList(props) {
  // state
  const [pageTitle, setpageTitle] = useState(''); // do something with this sometime?
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  // const [doneOnce, setDoneOnce] = useState(false);

  // props
  const { handleToUpdate } = props; // function to update current item id in ManageCollection
  const { collectionData } = props;
  const { isFetching } = props;
  const { fieldMap } = props;
  const { forceHeaderRefresh } = props;
  const { showAlert } = props;

  // we be stylin
  const classes = useStyles();

  // effects
  useEffect(() => {
    // column with buttons, refresh etc
    const lastColumn = {
      field: 'action',
      headerName: ' ',
      width: 50,
      disableColumnMenu: true,
      // eslint-disable-next-line react/display-name
      renderCell: () => (
        <IconButton aria-label="Choose this item" size="small">
          <NavigateNextIcon fontSize="inherit" />
        </IconButton>
      ),
      // eslint-disable-next-line react/display-name
      renderHeader: () => (
        <IconButton
          aria-label="Refresh List"
          size="small"
          onClick={() => {
            forceHeaderRefresh();
            showAlert('List updated.', {
              variant: 'info',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }}
        >
          <SyncIcon fontSize="inherit" />
        </IconButton>
      ),
    };

    function getColumnsDefinition(collectionHeaders) {
      const { collectionStructure } = collectionHeaders;
      const buildArray = [];

      for (
        let structureIndex = 0;
        structureIndex < collectionStructure.length;
        structureIndex += 1
      ) {
        const singleRowObject = {};
        let fieldDefinition = {};

        const { sourceFieldName } = collectionStructure[structureIndex];
        const { fieldName } = collectionStructure[structureIndex];

        /*
        console.log(
          'collectionStructure:',
          collectionStructure[structureIndex],
        );
        console.log('structureIndex:', structureIndex);
        console.log('getColumnsDefinition, sourceFieldName:', sourceFieldName);
*/

        // get field definitions from field map
        if (typeof fieldMap[sourceFieldName] !== 'undefined') {
          fieldDefinition = fieldMap[sourceFieldName];
        } else {
          fieldDefinition = {
            dataType: 'string',
            fieldTitle: sourceFieldName,
            inputType: 'text',
          };
          // console.log('Field Definition Missing:', sourceFieldName);
        }

        singleRowObject.field = fieldName;
        singleRowObject.headerName = fieldDefinition.fieldTitle;

        // set column width if necessary
        if (fieldDefinition?.columnWidth) {
          if (fieldDefinition.columnWidth === 'narrow') {
            singleRowObject.width = 100;
          } else {
            singleRowObject.width = fieldDefinition.columnWidth;
          }
        } else {
          singleRowObject.flex = 1;
        }

        // set column hidden if necessary
        if (fieldDefinition?.hidden) {
          singleRowObject.hide = true;
        }

        // if fieldorder is set then use it, otherwise just push it in
        if (
          typeof collectionStructure[structureIndex].fieldOrder !== 'undefined'
        ) {
          buildArray[collectionStructure[structureIndex].fieldOrder] =
            singleRowObject;
        } else {
          buildArray.push(singleRowObject);
        }
      }

      // adds column with icons / buttons (defined above)
      buildArray.push(lastColumn);

      return buildArray;
    }

    if (
      typeof collectionData.collectionHeaders !== 'undefined' &&
      typeof collectionData.collectionItems !== 'undefined' &&
      typeof fieldMap !== 'undefined' &&
      !isEmptyObject(collectionData.collectionHeaders) &&
      !isEmptyObject(fieldMap) &&
      !isEmptyObject(collectionData.collectionItems)
    ) {
      const colArray = getColumnsDefinition(
        collectionData.collectionHeaders,
        fieldMap,
      );

      const rowArray = collectionData.collectionItems;

      /*
      console.log('column array:', colArray);
      console.log('row array:', rowArray);
      */

      setColumns(colArray);
      setRows(rowArray);

      if (
        typeof collectionData.collectionHeaders.collectionName !== 'undefined'
      ) {
        setpageTitle(collectionData.collectionHeaders.collectionName);
      }

      /*
      console.log('fieldMap:', fieldMap);
      console.log('columns:', colArray);
      console.log('rows:', rowArray);
      */
    }
  }, [fieldMap, collectionData, forceHeaderRefresh, showAlert]);

  // component output
  return (
    <>
      <DataGrid
        autoPageSize
        className={classes.root}
        hideFooterSelectedRowCount="false"
        rows={rows}
        columns={columns}
        loading={isFetching}
        density="compact"
        disableSelectionOnClick={false}
        autoHeight={false}
        hideFooter={false}
        border={0}
        borderColor="primary.main"
        onRowClick={(newSelection) => {
          handleToUpdate(newSelection.row.id);
        }}
        style={{ borderRadius: '4px' }}
      />
    </>
  );
}

/** ***
 * End Main Component
 */
