// eslint-disable-next-line no-use-before-define
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// 1st party functions
import { isEmptyObject, filterObjectByArray } from '../utils/generalUtils';
import GridEdit from './GridEdit';

/**
 * DataSection Component starts here
 */

export default function DataSection(props) {
  // state variables
  const outputData = [];
  let sectionTitle = '';

  const {
    sectionKey,
    isFetching,
    fieldLayout,
    parentKey,
    fieldMap,
    sectionData,
    handleItemChange,
    modifiedItems,
  } = props;

  // functions for storing form data in / retrieving from
  // local component state

  // if section data is valid
  if (
    isFetching !== true &&
    typeof sectionData === 'object' &&
    !isEmptyObject(sectionData) &&
    !isEmptyObject(fieldMap)
  ) {
    // if field definitions for this section are valid
    if (
      typeof fieldLayout === 'object' &&
      typeof fieldLayout[sectionKey] === 'object'
    ) {
      const sectionFieldLayout = fieldLayout[sectionKey];

      // set section title
      if (typeof sectionFieldLayout.sectionTitle === 'string') {
        sectionTitle = sectionFieldLayout.sectionTitle;
      } else {
        sectionTitle = 'No section title';
      }

      // iterate through each object of field Definition,
      // matching it against the data and displaying what matches

      const sectionKeys = Object.keys(sectionFieldLayout);
      for (let dsIndex = 0; dsIndex < sectionKeys.length; dsIndex += 1) {
        const theDataSectionKey = sectionKeys[dsIndex];

        if (theDataSectionKey !== 'sectionTitle') {
          if (typeof sectionData === 'object') {
            const fieldKeys = Object.keys(
              sectionFieldLayout[theDataSectionKey],
            );

            for (let fkIndex = 0; fkIndex < fieldKeys.length; fkIndex += 1) {
              const theFieldKey = fieldKeys[fkIndex];

              let fieldTitle = '';
              let fieldValue = '';
              let dataType = '';
              let fieldDefinition = '';

              if (
                typeof sectionFieldLayout[theDataSectionKey] === 'object' &&
                typeof sectionFieldLayout[theDataSectionKey][theFieldKey] ===
                  'object'
              ) {
                fieldDefinition = { ...fieldMap[theFieldKey] };

                fieldTitle = fieldDefinition?.fieldTitle;
                dataType = fieldDefinition?.dataType;

                if (typeof sectionData[theFieldKey] !== 'undefined') {
                  fieldValue = sectionData[theFieldKey];
                }

                if (
                  typeof fieldDefinition.selectOptions === 'object' &&
                  !isEmptyObject(fieldDefinition.selectOptions)
                ) {
                  // do something with select options
                  const fullValueArray = fieldDefinition.selectOptions;

                  // do something with select options that have dependencies
                  if (typeof fieldDefinition.fieldDependencies === 'object') {
                    /*
                    console.log(
                      'Found a field with dependencies: ',
                      theFieldKey,
                    );
                      */

                    const depKeys = Object.keys(
                      fieldDefinition.fieldDependencies,
                    );

                    /*
                    console.log(
                      'The keys of the dependency fields are:',
                      depKeys,
                    );
                    */

                    for (
                      let depIndex = 0;
                      depIndex < depKeys.length;
                      depIndex += 1
                    ) {
                      // theFieldKey = key of current field (the drop down list
                      // that needs to be filtered)

                      // masterKey = key of 'master' field that controls the
                      // available values in the current field
                      const masterKey = depKeys[depIndex];

                      // masterKeyValue = the currently selected choice from the
                      // master field
                      const masterKeyValue = sectionData[masterKey];

                      // console.log('Current master key value:', masterKeyValue);

                      // filterKeyList = object with index = key of master field, each item
                      // an array of values to restrict the current field to based on the value
                      // of the master field
                      const filterKeyList =
                        fieldDefinition.fieldDependencies[depKeys[depIndex]];

                      /*
                      console.log(
                        'Dependency #',
                        depIndex + 1,
                        'is:',
                        masterKey,
                        'with value',
                        masterKeyValue,
                        'taken from',
                        filterKeyList,
                      );
                      */

                      // list of all the possible values for the current field

                      const filterKeyArray = filterKeyList[masterKeyValue];

                      const theNewOptionList = filterObjectByArray(
                        fullValueArray,
                        filterKeyArray,
                      );
                      /*
                      console.log('Final filter list is:', filterKeyArray);
                      console.log(
                        'It will need to filter the list:',
                        fullValueArray,
                      );

                      console.log('the new option list is:', theNewOptionList);
*/
                      fieldDefinition.selectOptions = theNewOptionList;

                      /*  console.log(
                        'the new field map entry is:',
                        JSON.stringify(fieldDefinition),
                        );
    */
                    }
                  }
                }

                let isModified = false;

                if (
                  typeof modifiedItems !== 'undefined' &&
                  Array.isArray(modifiedItems) &&
                  modifiedItems.includes(theFieldKey)
                ) {
                  isModified = true;
                }

                outputData.push({
                  key: theFieldKey,
                  dataSectionKey: theDataSectionKey,
                  fieldTitle,
                  fieldValue,
                  isModified,
                  dataType,
                  fieldDefinition,
                });
              }
            }
          }
        }
      }

      // console.log('the old field map is:', JSON.stringify(fieldMap));
      // console.log('the NEW field map is:', JSON.stringify(newFieldMap));

      return (
        <>
          <Box
            style={{ width: '100%', padding: '10px 10px 0px 10px' }}
            key={`${parentKey}box1`}
          >
            <Box style={{ width: '100%' }} key={`${parentKey}box2`}>
              <Typography
                style={{
                  color: '#151A30',
                  fontSize: '1.1em',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                key={`${parentKey}t1`}
              >
                {sectionTitle}
              </Typography>
            </Box>
            {isEmptyObject(outputData)
              ? 'Empty'
              : outputData.map((fieldData, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    key={`${parentKey}grid1${outputData[index].key}`}
                  >
                    <GridEdit
                      key={`${parentKey}grid3${outputData[index].key}`}
                      parentKey={parentKey}
                      fieldData={fieldData}
                      fieldName={outputData[index].key}
                      handleItemChange={handleItemChange}
                      isModified={fieldData?.isModified}
                    />
                  </Grid>
                ))}
          </Box>
        </>
      );
    }
  }

  return null;
}
