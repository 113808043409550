// React
// eslint-disable-next-line
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { navigate } from 'gatsby';

// material UI
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';

import IconButton from '@material-ui/core/IconButton';
import ExitItemIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/AddCircle';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

import Drawer from '@material-ui/core/Drawer';

import FormSubmitIcon from '@material-ui/icons/PlaylistAddCheck';
import ResetIcon from '@material-ui/icons/RotateLeft';

import { useSnackbar } from 'notistack';

import QTDialog from '../QTDialog';

// shared includes
import { UserContext } from '../../providers/UserProvider'; // 'global' user object
import {
  getSingleRecord,
  putSingleRecord,
  getCollectionList,
} from '../utils/dataFunctions'; // API data functions
import { isEmptyObject, logMessage } from '../utils/generalUtils';

// page-specific includes
import CollectionList from './CollectionList';
import ItemDetail from './ItemDetail';

export default function ManageCollection(props) {
  // state variables
  const [activePage, setActivePage] = useState(0);
  const [isFetching, setIsFetching] = useState(0);
  const [currentItemArray, setCurrentItemArray] = useState({});
  const [modifiedItemArray, setModifiedItemArray] = useState({});
  const [originalItem, setOriginalItem] = useState({});
  const [fieldMap, setFieldMap] = useState({});
  const [currentItemId, setCurrentItemId] = useState(null);
  const [collectionData, setCollectionData] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogBody, setDialogBody] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogTarget, setDialogTarget] = React.useState('');

  // hooks
  const { enqueueSnackbar } = useSnackbar();
  const { collectionName, siblingCollections, adminOnly } = props;

  const contextObject = useContext(UserContext);
  const { isVerifiedUser } = contextObject;
  const { firebaseToken } = contextObject;

  // force header refresh

  async function forceHeaderRefresh() {
    try {
      setIsFetching(true);
      logMessage(
        'forceHeaderRefresh',
        'Getting collectionitem headers for',
        collectionName,
        'ManageCollection/index.jsx',
      );

      getCollectionList(collectionName, firebaseToken).then((returnObject) => {
        setCollectionData(returnObject);
        setIsFetching(false);
      });
    } catch (e) {
      logMessage(
        'forceHeaderRefresh',
        'Unhandled Error',
        e,
        'ManageCollection/index.jsx',
        'ERROR',
      );
      setIsFetching(false);
    }
  }

  // handle display page change
  const handlePageChange = (pageToChoose) => {
    setActivePage(pageToChoose);
  };

  // reset data dialog
  const handleFormReset = () => {
    // console.log('Toolbar button: Reset data.');

    if (typeof originalItem[currentItemId] === 'object') {
      setDialogTarget('reset');
      setDialogTitle('Reset Data');
      setDialogBody(
        'This will reset the values for this item to what they were originally when the item loaded.  Please choose OK to proceed, or Cancel to abort.',
      );
      setOpenDialog(true);
    }
  };

  // item exit dialog
  const handleExitItem = () => {
    // console.log('Toolbar button: Exit item.');

    if (
      typeof modifiedItemArray[currentItemId] === 'object' &&
      modifiedItemArray[currentItemId].length > 0
    ) {
      setDialogTarget('exit');
      setDialogTitle('Unsaved Changes');
      setDialogBody(
        'You have unsaved changes, please choose OK to leave without saving these changes, or Cancel to abort.',
      );
      setOpenDialog(true);
    } else {
      handlePageChange(0);
    }
  };

  // initiate quote workflow
  const initiateQuoteWorkflow = (bridgeId) => {
    // console.log('Toolbar button: Quote.');

    if (
      typeof modifiedItemArray[currentItemId] === 'object' &&
      modifiedItemArray[currentItemId].length > 0
    ) {
      setDialogTarget('exit');
      setDialogTitle('Unsaved Changes');
      setDialogBody(
        'You have unsaved changes, please choose OK to proceed without saving these changes, or Cancel to abort.',
      );
      setOpenDialog(true);
    } else {
      navigate(`/app/processpricing/${currentItemId}/${bridgeId}`);
    }
  };

  // handle closing the dialog
  function handleClose() {
    setOpenDialog(false);
  }

  // handle choosing 'ok' in the dialog
  const handleCloseOk = (target) => {
    const newModifiedItems = { ...modifiedItemArray };

    switch (target) {
      case 'reset':
        if (typeof originalItem[currentItemId] === 'object') {
          const newCurrentItemArray = {
            ...currentItemArray,
            [currentItemId]: { ...originalItem[currentItemId] },
          };

          if (typeof newModifiedItems === 'object') {
            newModifiedItems[currentItemId] = [];
            setModifiedItemArray(newModifiedItems);
          }

          setCurrentItemArray(newCurrentItemArray);
          if (enqueueSnackbar !== null) {
            enqueueSnackbar('Data has been reset to last saved version.', {
              variant: 'info',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
        } else {
          console.error('No original data.');
        }
        break;
      case 'exit':
        handlePageChange(0);
        break;
      default:
        console.log('Nothing to do for target', target);
    }

    setOpenDialog(false);
  };

  // handle data update
  async function handleFormSubmit() {
    putSingleRecord(
      collectionName,
      currentItemId,
      currentItemArray[currentItemId],
      firebaseToken,
      enqueueSnackbar,
    ).then(async (response) => {
      const newModifiedItems = { ...modifiedItemArray };

      if (typeof response.status !== 'undefined' && response.status === 200) {
        // resets modified status

        if (typeof newModifiedItems === 'object') {
          newModifiedItems[currentItemId] = [];
          setModifiedItemArray(newModifiedItems);
        }

        // changes undo state to latest save
        setOriginalItem({
          ...originalItem,
          [currentItemId]: { ...currentItemArray[currentItemId] },
        });

        forceHeaderRefresh();
      }
    });
  }

  // handle item change
  const handleItemChange = (theEvent) => {
    const dataObj = { [theEvent.target.name]: theEvent.target.value };
    let newModifiedItems = {};

    if (typeof modifiedItemArray === 'object') {
      newModifiedItems = { ...modifiedItemArray };
    }

    // keeps an array of modified items
    if (Array.isArray(newModifiedItems[currentItemId])) {
      if (!newModifiedItems[currentItemId].includes(theEvent.target.name)) {
        newModifiedItems[currentItemId].push(theEvent.target.name);
      }
    } else {
      newModifiedItems[currentItemId] = [theEvent.target.name];
    }

    let newObj = {};
    if (typeof currentItemArray[currentItemId] !== 'object') {
      newObj = { ...currentItemArray, [currentItemId]: { ...dataObj } };
    } else {
      newObj = {
        ...currentItemArray,
        [currentItemId]: {
          ...currentItemArray[currentItemId],
          ...dataObj,
        },
      };
    }

    //
    console.log('[ManageCollection / handleItemChange: dataObj]:', dataObj);
    console.log('[ManageCollection / handleItemChange: newObj]:', newObj);

    setCurrentItemArray(newObj);
    setModifiedItemArray(newModifiedItems);
  };

  // only triggers change if user context changes or
  // once when it is set
  const getSingleItem = useCallback(
    async (itemId, itemType = '') => {
      if (isVerifiedUser && typeof firebaseToken === 'string') {
        try {
          setIsFetching(true);

          logMessage(
            'getSingleItem',
            `Getting item ${itemType}`,
            itemId,
            'ManageCollection/index.jsx',
          );

          const returnObject = await getSingleRecord(
            itemType,
            itemId,
            firebaseToken,
          );

          setIsFetching(false);
          return returnObject;
        } catch (e) {
          logMessage(
            'getSingleItem',
            'Unhandled Error',
            e,
            'ManageCollection/index.jsx',
            'ERROR',
          );
          return {};
        }
      }
      return {};
    },
    [firebaseToken, isVerifiedUser],
  );

  function handleToUpdate(childItemId) {
    setCurrentItemId(childItemId);

    if (
      typeof currentItemArray[childItemId] !== 'object' ||
      childItemId === 'new'
    ) {
      setIsFetching(true);
      getSingleItem(childItemId, collectionName).then((theItem) => {
        let tempObj = {};
        if (!isEmptyObject(theItem)) {
          if (isEmptyObject(currentItemArray)) {
            setCurrentItemArray({
              [childItemId]: { ...theItem },
            });
            setModifiedItemArray([]);
          } else {
            tempObj = { ...currentItemArray };
            tempObj[childItemId] = theItem;
            setCurrentItemArray(tempObj);
          }
          if (isEmptyObject(originalItem)) {
            setOriginalItem({ [childItemId]: { ...theItem } });
          } else {
            tempObj = { ...originalItem };
            tempObj[childItemId] = theItem;
            setOriginalItem(tempObj);
          }
        }
        setIsFetching(false);
        setActivePage(1);
      });
    } else {
      setActivePage(1);
    }
  }

  // switch to single item or collection
  const { urlId } = props;

  if (typeof urlId !== 'undefined' && currentItemId !== urlId) {
    handleToUpdate(urlId);
    setActivePage(1);
  }

  // only triggers change if user context changes or
  // once when it is set
  // pulls data for list view

  // loads field map data one time
  useEffect(() => {
    // set fieldmap
    getSingleItem('', 'fieldmap').then((theItem) => {
      setFieldMap(theItem.fieldMap);
    });
  }, [getSingleItem]);

  // handles click from list view (choice of current item)

  // handle add new item
  const handleAddItem = async () => {
    handleToUpdate('new');
  };

  // handles switching between content based on activePage state
  function getContent(pageIndex) {
    let itemDetail = <></>;
    switch (pageIndex) {
      case 0: // list items in collection
        return (
          <CollectionList
            handleToUpdate={handleToUpdate}
            showAlert={enqueueSnackbar}
            isFetching={isFetching}
            collectionName={collectionName}
            collectionData={collectionData}
            fieldMap={fieldMap}
            forceHeaderRefresh={forceHeaderRefresh}
          />
        );
      case 1: // modify item
        itemDetail = (
          <ItemDetail
            collectionName={collectionName}
            siblingCollections={siblingCollections}
            currentItem={currentItemArray[currentItemId]}
            currentItemId={currentItemId}
            setPage={handlePageChange}
            showAlert={enqueueSnackbar}
            fieldMap={fieldMap}
            handleItemChange={handleItemChange}
            modifiedItems={modifiedItemArray[currentItemId]}
            adminOnly={adminOnly}
          />
        );
        return itemDetail;
      case 2: // new item
        return (
          <ItemDetail
            collectionName={collectionName}
            currentItem={{}}
            currentItemId="new"
            setPage={handlePageChange}
            showAlert={enqueueSnackbar}
            fieldMap={fieldMap}
            handleItemChange={handleItemChange}
            modifiedItems={[]}
            adminOnly={adminOnly}
          />
        );
      default:
        return 'Unknown pageIndex';
    }
  }

  // could be more elegant and use classes
  // but basically this makes the submit button
  // change colour depending on form status

  let submitStyle = { fill: 'white' };
  if (
    typeof modifiedItemArray !== 'undefined' &&
    typeof modifiedItemArray[currentItemId] !== 'undefined' &&
    modifiedItemArray[currentItemId].length > 0
  ) {
    submitStyle = {
      animationName: 'glowGreen',
      animationDuration: '3s',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
    };
  }

  // toolbar
  function toolbarContent(toolbarPage) {
    function optionalButtons() {
      const returnButtonArray = [];
      if (collectionName === 'rfqs') {
        returnButtonArray.push(
          <IconButton
            aria-label="Initiate Quote Workflow"
            title="Create Quote"
            size="small"
            key="quoter"
            onClick={() => {
              console.log(currentItemArray);
              if (
                typeof currentItemArray[currentItemId]?.rfqBridge?.id !==
                'undefined'
              ) {
                initiateQuoteWorkflow(
                  currentItemArray[currentItemId].rfqBridge.id,
                );
              }
            }}
            style={{
              paddingTop: '20px',
            }}
          >
            <MoneyIcon fontSize="large" style={{ fill: 'white' }} />
          </IconButton>,
        );
      }

      return returnButtonArray;
    }

    let toolbarButtons = <></>;

    switch (toolbarPage) {
      case 0:
        toolbarButtons = (
          <>
            <IconButton
              title="Add new item"
              aria-label="Add new item"
              size="small"
              onClick={handleAddItem}
            >
              <AddIcon fontSize="large" style={{ fill: 'white' }} />
            </IconButton>
          </>
        );
        break;

      case 1:
        toolbarButtons = (
          <>
            <IconButton
              title="Reset to most recently saved values"
              aria-label="Reset Changes"
              size="small"
              onClick={handleFormReset}
            >
              <ResetIcon fontSize="large" style={{ fill: 'white' }} />
            </IconButton>
            <IconButton
              aria-label="Submit Changes"
              title="Submit changed values"
              size="small"
              onClick={handleFormSubmit}
              style={{
                paddingTop: '20px',
              }}
            >
              <FormSubmitIcon fontSize="large" style={submitStyle} />
            </IconButton>
            <IconButton
              aria-label="Exit this item"
              title="Exit this item"
              size="small"
              onClick={() => {
                handleExitItem();
              }}
              style={{
                paddingTop: '20px',
              }}
            >
              <ExitItemIcon fontSize="large" style={{ fill: 'white' }} />
            </IconButton>
            {optionalButtons()}
          </>
        );
        break;
      default:
        break;
    }

    return (
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          borderRadius: '0px',
          borderTop: '1px solid #1c2235',
          top: '48px',
          bottom: 'auto',
          width: '46px',
          height: '100%',
          backgroundColor: '#222b45',
          color: 'white',
        }}
      >
        {
          // this is the shadow
        }
        <div
          style={{
            position: 'fixed',
            height: '2px',
            top: '46px',
            width: '46px',
            display: 'block',
            boxShadow: '0px 3px 3px 0px #161a28',
          }}
        />
        {
          // this is the icon container
        }
        <div
          style={{
            paddingTop: '20px',
            marginLeft: '5px',
            marginRight: '5px',
            width: '40px',
            display: 'block',
          }}
        >
          {toolbarButtons}
        </div>
      </AppBar>
    );
  }

  // styles for leaving room for toolbar - normal
  const normalStyle = {
    flexGrow: 1,
    height: '100%',
  };

  // styles for leaving room for toolbar - with toolbar
  const toolbarStyle = {
    flexGrow: 1,
    height: '100%',
    paddingRight: '45px',
  };

  let currentStyle = normalStyle;
  const toolbar = toolbarContent(activePage);
  currentStyle = toolbarStyle;

  const [doneOnce, setDoneOnce] = useState(false);

  if (collectionData.length === 0 && !doneOnce) {
    setDoneOnce(true);
    forceHeaderRefresh();
  }

  return (
    <>
      <QTDialog
        open={openDialog}
        target={dialogTarget}
        dialogTitle={dialogTitle}
        dialogTarget={dialogTarget}
        dialogBody={dialogBody}
        handleClose={handleClose}
        handleCloseOk={handleCloseOk}
        handleCloseCancel={handleClose}
      />
      <Drawer
        variant="permanent"
        anchor="right"
        style={{ flexShrink: 0 }}
        PaperProps={{ style: { borderLeft: '0' } }}
      >
        {toolbar}
      </Drawer>
      <div style={currentStyle} key="manageCollectionBlock">
        <div
          style={{
            flexGrow: 1,
            height: '100%',
          }}
        >
          <Paper
            elevation={2}
            style={{
              display: 'flex',
              height: '100%',
              marginTop: 0,
              padding: 0,
            }}
          >
            {getContent(activePage)}
          </Paper>
        </div>
      </div>
    </>
  );
}
