// eslint-disable-next-line no-use-before-define
import React from 'react';
import { navigate } from 'gatsby';
import { Button } from '@material-ui/core';
import { getSingleRecord } from './utils/dataFunctions'; // API data functions
import { UserContext } from '../providers/UserProvider'; // 'global' user object

function FieldMap() {
  const contextObject = React.useContext(UserContext);

  const { isVerifiedUser } = contextObject;
  const { firebaseToken } = contextObject;

  const getSingleItem = React.useCallback(
    async (itemId, itemType = '') => {
      if (isVerifiedUser && typeof firebaseToken === 'string') {
        try {
          console.log(
            'getSingleItem',
            `Getting item ${itemType}`,
            itemId,
            'ManageCollection/index.jsx',
          );

          const returnObject = await getSingleRecord(
            itemType,
            itemId,
            firebaseToken,
          );

          return returnObject;
        } catch (e) {
          console.log(
            'getSingleItem',
            'Unhandled Error',
            e,
            'ManageCollection/index.jsx',
            'ERROR',
          );
          return {};
        }
      }
      return {};
    },
    [firebaseToken, isVerifiedUser],
  );

  let theOutput = <></>;

  getSingleItem('', 'fieldmap').then((theItem) => {
    console.log('old field map', theItem.fieldMap);
  });
  getSingleItem('', 'fieldstructure').then((theItem) => {
    console.log('new field structure:', theItem);
  });
  getSingleItem('', 'gatewaymap').then((theItem) => {
    console.log('gatewaymap:', theItem);
  });

  if (typeof window !== 'undefined') {
    theOutput = (
      <>
        <div style={{ width: '100%', marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/app/fieldmap');
            }}
          >
            Refresh
          </Button>
        </div>
      </>
    );
  }

  return theOutput;
}

export default FieldMap;
