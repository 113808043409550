// eslint-disable-next-line no-use-before-define
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import QTTextField from '../QTTextField';
import QTDropDown from '../QTDropDown';
import QTAutoComplete from '../QTAutoComplete';

export default function GridEdit(props) {
  // init
  let defaultValue = null;
  // let dataType = 'string'; // todo: this is for generating suffixes (m), etc
  let isRequired = false;
  let inputType = 'text';
  let dateStringValue = '';
  let dateYear = '';
  let dateMonth = '';
  let dateDay = '';

  let sourceOptions = null;

  const { handleItemChange, fieldName, parentKey, fieldData, isModified } =
    props;

  const fieldDef = fieldData.fieldDefinition;

  // styles
  const gridStyle = {
    width: '100%',
    minWidth: '250px',
    marginBottom: '8px',
    marginTop: '8px',
  };

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiOutlinedInput-root': {
        fontSize: '0.9375rem',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'white',
      },
    },
  }));

  const classes = useStyles();
  // end styles

  if (typeof fieldDef !== 'undefined' && typeof fieldData !== 'undefined') {
    if (
      typeof fieldDef.isRequired === 'undefined' ||
      fieldDef.isRequired === null
    ) {
      isRequired = false;
    } else if (fieldDef.isRequired === 'TRUE' || fieldDef.isRequired === true) {
      isRequired = true;
    } else {
      isRequired = false;
    }
    if (
      typeof fieldDef.dataType === 'undefined' ||
      fieldDef.dataType === null
    ) {
      // dataType = 'string';
    } else {
      // dataType = fieldDef.dataType;
    }

    if (
      typeof fieldDef.inputType === 'undefined' ||
      fieldDef.inputType === null
    ) {
      inputType = 'text';
    } else {
      inputType = fieldDef.inputType;
    }

    defaultValue = fieldData.fieldValue;
  } else {
    console.error('fieldDef not (GridValue), props:', props);
  }

  let modifiedStyle = {};
  if (isModified) {
    modifiedStyle = { borderColor: 'blue', borderWidth: '2px' };
  }

  let fieldInput = <></>;
  let additionalProperties = {};
  let textFieldType = 'text';
  switch (inputType) {
    case 'date':
      // this is crap, need to stop storing dates as text and do it right

      if (typeof defaultValue === 'string' && defaultValue.length > 9) {
        dateYear = defaultValue.substr(0, 4);
        dateMonth = defaultValue.substr(5, 2);
        dateDay = defaultValue.substr(8, 2);

        // var dateStringValue = new Date({ defaultValue }).toDateString();
        dateStringValue = `${dateYear}-${dateMonth}-${dateDay}`;
      } else {
        const currentDate = new Date();

        dateYear = currentDate.getFullYear();
        dateMonth = `0${currentDate.getMonth() + 1}`.slice(-2);
        dateDay = `0${currentDate.getDate()}`.slice(-2);

        dateStringValue = `${dateYear}-${dateMonth}-${dateDay}`;
      }

      fieldInput = (
        <>
          <QTTextField
            id={`${parentKey}.${fieldName}`}
            label={fieldData.fieldTitle}
            type="date"
            variant="outlined"
            size="small"
            required={isRequired}
            key={`${parentKey}.${fieldName}`}
            defaultValue={dateStringValue}
            name={fieldName}
            style={({ ...gridStyle }, { ...modifiedStyle })}
            onChange={handleItemChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>
      );
      break;
    case 'longtext':
      additionalProperties = { multiline: true, rows: 6 };
    // falls through
    case 'integer':
    // falls through
    case 'number':
      if (inputType === 'number') {
        textFieldType = 'number';
      }
    // falls through
    case 'phone':
      if (inputType === 'phone') {
        textFieldType = 'tel';
      }
    // falls through
    case 'email':
      if (inputType === 'email') {
        textFieldType = 'email';
      }
    // falls through
    case 'text':
      fieldInput = (
        <QTTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...additionalProperties}
          variant="outlined"
          size="small"
          type={textFieldType}
          required={isRequired}
          key={`${parentKey}.${fieldName}`}
          id={`${parentKey}.${fieldName}`}
          name={fieldName}
          value={defaultValue}
          style={{ ...gridStyle }}
          label={fieldData.fieldTitle}
          onChange={handleItemChange}
        />
      );
      break;
    case 'yesno':
    // falls through
    case 'select':
      if (inputType === 'select') {
        sourceOptions = fieldDef.selectOptions;
      } else if (inputType === 'yesno') {
        sourceOptions = {
          TRUE: 'Yes',
          FALSE: 'No',
        };
      }

      if (defaultValue !== '') {
        if (typeof sourceOptions[defaultValue] === 'undefined') {
          defaultValue = '';
        }
      }

      fieldInput = (
        <QTDropDown
          sourceOptions={sourceOptions}
          parentKey={parentKey}
          key={`${parentKey}.${fieldName}`}
          id={`${parentKey}.${fieldName}`}
          fieldName={fieldName}
          fieldTitle={fieldData.fieldTitle}
          handleItemChange={handleItemChange}
          isRequired={isRequired}
          isModified={isModified}
          defaultValue={defaultValue}
        />
      );

      break;
    case 'autocomplete':
      if (
        typeof fieldDef.searchCollection !== 'undefined' &&
        typeof fieldDef.searchDisplayField !== 'undefined'
      ) {
        fieldInput = (
          <QTAutoComplete
            searchCollection={fieldDef.searchCollection}
            searchDisplayField={fieldDef.searchDisplayField}
            parentKey={parentKey}
            key={`${parentKey}.${fieldName}`}
            id={`${parentKey}.${fieldName}`}
            fieldName={fieldName}
            fieldTitle={fieldData.fieldTitle}
            handleItemChange={handleItemChange}
            isRequired={isRequired}
            isModified={isModified}
            defaultValue={defaultValue}
          />
        );
      } else {
        fieldInput = <>Correct parameters not provided for AutoComplete.</>;
      }

      break;
    case 'mailto':
      fieldInput = (
        <QTTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...additionalProperties}
          variant="outlined"
          size="small"
          type="email"
          required={isRequired}
          key={`${parentKey}.${fieldName}`}
          id={`${parentKey}.${fieldName}`}
          name={fieldName}
          value={defaultValue}
          style={{ ...gridStyle }}
          label={fieldData.fieldTitle}
          onChange={handleItemChange}
        />
      );
      break;
    default:
      // code block
      fieldInput = (
        <>You need to render a control for input type {inputType}.</>
      );
  }

  return (
    <Grid className={classes.root} item xs={12} key={`${parentKey}grid4`}>
      {fieldInput}
    </Grid>
  );
}
