// eslint-disable-next-line no-use-before-define
import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const NewFormControl = withStyles({
  root: {
    width: '100%',
    minWidth: '250px',
    marginBottom: '8px',
    marginTop: '8px',
    '& input:valid + fieldset': {
      borderRightColor: 'green',
      borderRightWidth: 6,
      borderWidth: 1,
    },
    '& input:invalid + fieldset': {
      borderRightColor: 'red',
      borderRightWidth: 6,
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderRightWidth: 6,
      borderWidth: 1,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '0.9375rem',
      borderRight: '10px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
      borderRight: '0px',
    },
  },
})(FormControl);

/*
const selectGreen = {
  '& .MuiSelect-select': {
    backgroundColor: 'white',
    borderRight: '5px green solid',
  },
};

const selectRed = {
  '& .MuiSelect-select': {
    backgroundColor: 'white',
    borderRight: '5px green solid',
  },
};
*/

function QTDropDown(props) {
  const {
    sourceOptions,
    parentKey,
    fieldName,
    fieldTitle,
    handleItemChange,
    isRequired,
    defaultValue,
  } = props;

  // console.log('fieldname', fieldName, 'sourceoptions:', sourceOptions);

  const theDefaultValue = String(defaultValue);

  function selectionError() {
    if (defaultValue === '' && isRequired) {
      return true;
    }
    return false;
  }

  const selectOptions = [];
  const optionKeys = Object.keys(sourceOptions);

  function validateDropDown(theEvent) {
    // console.log('dropdown changed:', theEvent);
    if (theEvent.value !== '') {
      handleItemChange(theEvent);
    }
  }

  selectOptions.push(
    <MenuItem
      disabled={isRequired}
      aria-label="-"
      key={`opt${parentKey}${fieldName}blank`}
      value=""
    >
      <em>-</em>
    </MenuItem>,
  );
  optionKeys.map((optionKey) => {
    selectOptions.push(
      <MenuItem
        key={`opt${parentKey}${fieldName}${optionKey}`}
        value={String(optionKey)}
      >
        {sourceOptions[optionKey]}
      </MenuItem>,
    );
    return null;
  });
  return (
    <NewFormControl
      error={selectionError()}
      variant="outlined"
      margin="dense"
      required={isRequired}
    >
      <InputLabel
        style={{
          backgroundColor: 'white',
          paddingLeft: '0px',
          paddingRight: '5px',
        }}
        htmlFor={`${parentKey}.${fieldName}`}
        id={`label${parentKey}${fieldName}`}
        key={`label${parentKey}${fieldName}`}
      >
        {fieldTitle}
      </InputLabel>
      <Select
        name={fieldName}
        labelId={`label${parentKey}${fieldName}`}
        id={`${parentKey}.${fieldName}`}
        value={theDefaultValue}
        onChange={validateDropDown}
        key={`sel${parentKey}${fieldName}`}
      >
        {selectOptions}
      </Select>
    </NewFormControl>
  );
}

export default QTDropDown;
