// 3rd party
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Router } from '@reach/router';

// 1st party
// import ManageQuotes from '../components/ManageQuotes';
import ManageCollection from '../components/ManageCollection';
import PrivateRoute from '../components/PrivateRoute';

import SignIn from '../components/SignIn';
import ProfilePage from '../components/ProfilePage';

import { getFirebase } from '../components/utils/firebase';
import FieldMap from '../components/FieldMap';

getFirebase();

const App = () => {
  // can put stuff here

  return (
    <>
      <Router>
        <PrivateRoute path="/app/fieldmap" component={FieldMap} />
        <PrivateRoute path="/app/signin" component={SignIn} />
        <PrivateRoute path="/app/profile" component={ProfilePage} />
        <PrivateRoute
          path="/app/managerfqs/:urlId"
          component={ManageCollection}
          collectionName="rfqs"
          siblingCollections={['pricing']}
        />
        <PrivateRoute
          path="/app/managerfqs"
          component={ManageCollection}
          collectionName="rfqs"
          siblingCollections={['pricing']}
        />
        <PrivateRoute
          path="/app/managebridges/:urlId"
          component={ManageCollection}
          collectionName="bridges"
          siblingCollections={['costings']}
        />
        <PrivateRoute
          path="/app/managebridges"
          component={ManageCollection}
          collectionName="bridges"
          siblingCollections={['costings']}
        />
        <PrivateRoute
          path="/app/manageorganisations/:urlId"
          component={ManageCollection}
          collectionName="organisations"
        />
        <PrivateRoute
          path="/app/manageorganisations"
          component={ManageCollection}
          collectionName="organisations"
        />
        <PrivateRoute
          path="/app/manageusers/:urlId"
          component={ManageCollection}
          collectionName="users"
        />
        <PrivateRoute
          path="/app/manageusers"
          component={ManageCollection}
          collectionName="users"
        />
        <PrivateRoute
          path="/app/managefields"
          component={ManageCollection}
          collectionName="fields"
        />
        <PrivateRoute
          path="/app/managemappings"
          component={ManageCollection}
          collectionName="mappings"
        />
      </Router>
    </>
  );
};

export default App;
