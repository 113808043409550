// eslint-disable-next-line no-use-before-define
import React from 'react';
import NumberFormat from 'react-number-format';

import { isEmptyObject, logMessage } from './utils/generalUtils';
import QTTextField from './QTTextField';

import '../styles/spinner.css';

function formatValue(theValue, theValueType = 'text', decimalCount = 2) {
  let theOutputString = '';

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const standardNumberFormatter = new Intl.NumberFormat('en-AU', {
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });

  const integerFormatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 0,
  });

  const percentageFormatter = new Intl.NumberFormat('en-AU', {
    style: 'percent',
    maximumFractionDigits: 0,
  });

  // console.log('formatValue:', theValue, 'valueType:', theValueType);
  if (typeof theValue !== 'undefined') {
    switch (theValueType) {
      case 'percentage':
        theOutputString = percentageFormatter.format(theValue);
        break;

      case 'double':
        theOutputString = standardNumberFormatter.format(theValue);
        break;

      case 'currency':
        theOutputString = currencyFormatter.format(theValue);
        break;

      case 'tonnage':
        theOutputString = new Intl.NumberFormat('en-AU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(theValue);
        break;

      case 'volume':
        theOutputString = new Intl.NumberFormat('en-AU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(theValue);
        break;

      case 'text':
      case 'string':
      default:
        theOutputString = theValue;
        break;
    }
  } else {
    theOutputString = '';
  }

  return theOutputString;
}

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            id: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

function NumberFormatPlain(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            id: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator
    />
  );
}

function NumberFormatDollar(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            id: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator
      prefix="$"
    />
  );
}

function NumberFormatVolume(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            id: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator
    />
  );
}

function QTGridEdit(props) {
  const { label, required, name, value, recalcFunction, valueType } = props;

  let modifiedValue = value;
  let inputComponent;

  switch (valueType) {
    case 'percentage':
      modifiedValue = Math.round(Number(value * 100));
      inputComponent = NumberFormatPercentage;
      break;

    case 'integer':
      // no decimal places integer
      modifiedValue = Math.round(Number(value));
      inputComponent = NumberFormatPlain;
      break;

    case 'currency':
      // no decimal places integer
      modifiedValue = Math.round(Number(value));
      inputComponent = NumberFormatDollar;
      break;

    case 'volume':
      // two decimal places
      modifiedValue = Math.round(Number(value * 100)) / 100;
      inputComponent = NumberFormatVolume;
      break;

    default:
      // two decimal places default
      modifiedValue = Math.round(Number(value * 100)) / 100;
      inputComponent = NumberFormatPlain;
      break;
  }

  return (
    <QTTextField
      label={label}
      variant="outlined"
      size="small"
      required={required}
      key={name}
      value={modifiedValue}
      onChange={recalcFunction}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      // note: InputProps = React Input object properties
      InputProps={{
        inputComponent,
      }}
      // note: inputprops = HTML input tag properties
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        style: {
          textAlign: 'right',
          paddingBottom: '5px',
          paddingTop: '5px',
          paddingRight: '10px',
          fontSize: '0.9375rem',
        },
        step: '1',
      }}
      style={{
        minWidth: 'auto',
        marginTop: '0px',
        marginBottom: '0px',
        maxWidth: '100px',
      }}
    />
  );
}

function displayField(
  theValue,
  valueType,
  key,
  align,
  extrastyle,
  editable = false,
  recalcFunction = null,
) {
  let outputTd = <td />;

  let theStyle = { ...extrastyle };

  if (Math.trunc(Number(theValue) * 100) / 100 === 0) {
    theStyle = { ...theStyle, color: 'lightgrey' };
  }

  if (editable) {
    const isRequired = false;
    let editControl = <></>;
    switch (valueType) {
      case 'percentage':
      case 'integer':
      case 'currency':
        editControl = (
          <QTGridEdit
            required={isRequired}
            name={key}
            value={theValue}
            valueType={valueType}
            recalcFunction={recalcFunction}
          />
        );
        break;

      default:
        editControl = (
          <div
            style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}
          >
            edit {valueType}
          </div>
        );
        break;
    }

    outputTd = (
      <td
        style={{
          textAlign: align,
          ...theStyle,
        }}
        key={`td${key}`}
      >
        {editControl}
      </td>
    );
  } else {
    outputTd = (
      <td
        key={`td${key}`}
        style={{
          paddingRight: '5px',
          paddingLeft: '5px',
          textAlign: align,
        }}
      >
        <div style={{ ...theStyle, paddingRight: '5px', paddingLeft: '0px' }}>
          {formatValue(theValue, valueType)}
        </div>
      </td>
    );
  }

  return outputTd;
}

// adds calculated field to data array
function addCalculatedField(
  originalObject,
  fieldName,
  fieldEditable,
  fieldValue,
  fieldValueType,
  fieldKey,
  fieldText = '',
) {
  let objectWithNewFieldAdded = {};

  const newField = {};
  newField[fieldName] = {
    editable: fieldEditable,
    fieldHash: fieldKey,
    text: fieldText,
    value: fieldValue,
    valueType: fieldValueType,
  };

  objectWithNewFieldAdded = { ...originalObject, ...newField };
  return objectWithNewFieldAdded;
}

function partsTableHeader(tableDefinition) {
  // header row

  const headerFieldOutputArray = [];

  if (
    typeof tableDefinition.columns !== 'undefined' &&
    tableDefinition.columns.length > 0
  ) {
    for (
      let thIndex = 0;
      thIndex < tableDefinition.columns.length;
      thIndex += 1
    ) {
      const currentHeaderId = tableDefinition.columns[thIndex];
      const currentHeaderDefinition =
        tableDefinition.columnDefinitions[currentHeaderId];

      const headerStyle = {
        fontWeight: 'bold',
      };

      //          console.log('headerinfo:', currentHeaderDefinition, currentHeaderId);

      headerFieldOutputArray.push(
        displayField(
          currentHeaderDefinition.description,
          'string', // header is a string
          currentHeaderId,
          currentHeaderDefinition.align,
          headerStyle,
          false, // headers aren't editable
          null, // no function needed
        ),
      );
    }

    return (
      <thead key="partstablehead">
        <tr
          key="trpartstablehead"
          valign="top"
          style={{ backgroundColor: 'rgb(239, 239, 239)' }}
        >
          {headerFieldOutputArray}
        </tr>
      </thead>
    );
  }

  return <></>;
  // end header row
}

export default function PricingSection(props) {
  const [formValues, setFormValues] = React.useState([]);
  const { partsTable, setPartsTable, colTotals, productTableDefinition } =
    props;

  const tableDefinition = { ...productTableDefinition };
  const recalcTable = (event) => {
    const inputId = event.target.name;
    let inputValue = event.target.value;

    const tempFV = { ...formValues };

    const openPPos = String(inputId).indexOf('(');
    const dividerPos = String(inputId).indexOf('|');
    const closePPos = String(inputId).indexOf(')', openPPos);

    const inputValueType = String(inputId).substring(openPPos + 1, closePPos);
    const gridPosToChange = String(inputId).substring(0, openPPos);
    const fieldNameToChange = String(inputId).substring(
      dividerPos + 1,
      openPPos,
    );

    const rowToChange = Number(String(inputId).substring(0, dividerPos));

    const outputPartsTableRows = [];
    if (inputValueType === 'percentage') {
      inputValue /= 100;
    }

    tempFV[gridPosToChange] = inputValue;
    setFormValues({ ...tempFV });

    for (let rowIndex = 0; rowIndex < partsTable.rows.length; rowIndex += 1) {
      const oPartRow = { ...partsTable.rows[rowIndex] };

      if (rowIndex === rowToChange) {
        // updating the live parts table state object
        oPartRow[fieldNameToChange].value = inputValue;
      }

      outputPartsTableRows[rowIndex] = oPartRow;
    }

    const partsTableCopy = { ...partsTable };
    partsTableCopy.rows = outputPartsTableRows;
    setPartsTable(partsTableCopy);
  };

  let returnContent = <></>;
  if (typeof partsTable === 'object' && !isEmptyObject(partsTable)) {
    // const titleColumn = false;
    const footerRow = true;

    const tableBodyArray = [];
    const tableHeadArray = [];

    // console.log('dispparts fieldmap:', localFieldMap);

    if (
      typeof partsTable?.rows !== 'undefined' &&
      typeof partsTable?.headers !== 'undefined'
    ) {
      const rowsData = partsTable.rows;
      const rowsDataKeys = Object.keys(rowsData);

      tableHeadArray.push(partsTableHeader(tableDefinition));

      // table body

      for (
        let rowsDataIndex = 0;
        rowsDataIndex < rowsDataKeys.length;
        rowsDataIndex += 1
      ) {
        const currentRowKey = rowsDataKeys[rowsDataIndex];
        let currentRow = { ...rowsData[currentRowKey] };

        // CALCULATED FIELDS
        // Calculated client-side so they can be updated live

        let markUpPercent = 0;

        if (typeof currentRow.col_markup !== 'undefined') {
          markUpPercent = currentRow.col_markup.value;
        }

        const partCost = currentRow.col_partCost.value;
        const partPrice = partCost * (1 + markUpPercent);
        const partQuantity = currentRow.col_partQuantity.value;

        const partTotalCost =
          currentRow.col_partCost.value * currentRow.col_partQuantity.value;
        const partTotalConcrete =
          currentRow.col_partConcrete.value * currentRow.col_partQuantity.value;
        const partTotalWeight =
          currentRow.col_partWeight.value * currentRow.col_partQuantity.value;
        const partTotalPrice = partPrice * partQuantity;

        const partMarkup = partTotalPrice - partTotalCost;

        let updatedRow = { ...currentRow };
        updatedRow = addCalculatedField(
          updatedRow,
          'partTotalCost',
          false,
          partTotalCost,
          'currency',
          'keyhere',
          '',
        );
        updatedRow = addCalculatedField(
          updatedRow,
          'partPrice',
          false,
          partPrice,
          'currency',
          'keyhere2',
          '',
        );
        updatedRow = addCalculatedField(
          updatedRow,
          'partTotalPrice',
          false,
          partTotalPrice,
          'currency',
          'keyhere3',
          '',
        );
        updatedRow = addCalculatedField(
          updatedRow,
          'partTotalConcrete',
          false,
          partTotalConcrete,
          'currency',
          'keyhere3',
          '',
        );
        updatedRow = addCalculatedField(
          updatedRow,
          'partTotalWeight',
          false,
          partTotalWeight,
          'currency',
          'keyhere3',
          '',
        );
        updatedRow = addCalculatedField(
          updatedRow,
          'partMarkup',
          false,
          partMarkup,
          'currency',
          'keyhere3',
          '',
        );
        currentRow = { ...updatedRow };
        // console.log(`r${rowsDataIndex}`, currentRow);

        // END CALCULATED FIELDS

        const fieldArray = [];
        for (
          let colIndex = 0;
          colIndex < tableDefinition.columns.length;
          colIndex += 1
        ) {
          let currentFieldEditable = false;
          const currentColumnKey = tableDefinition.columns[colIndex];
          const currentColumnDefinition =
            tableDefinition.columnDefinitions[currentColumnKey];
          const currentFieldType = currentColumnDefinition.valueType;

          let fieldValue;
          if (
            typeof formValues[`i[${rowsDataIndex}|${currentColumnKey}]`] !==
            'undefined'
          ) {
            fieldValue = formValues[`i[${rowsDataIndex}|${currentColumnKey}]`];
          } else if (typeof currentRow[currentColumnKey] !== 'undefined') {
            if (currentFieldType === 'string') {
              fieldValue = currentRow[currentColumnKey].text;
            } else if (
              typeof currentRow[currentColumnKey]?.value !== 'undefined'
            ) {
              fieldValue = currentRow[currentColumnKey].value;
            } else {
              fieldValue = currentRow[currentColumnKey].text;
            }
          }

          if (
            currentColumnDefinition?.editable ||
            currentRow[currentColumnKey]?.editable
          ) {
            currentFieldEditable = true;
          }

          fieldArray.push(
            displayField(
              fieldValue,
              currentColumnDefinition.valueType,
              `${rowsDataIndex}|${currentColumnKey}(${currentColumnDefinition.valueType})`,
              currentColumnDefinition.align,
              {},
              currentFieldEditable,
              recalcTable,
            ),
          );
        }

        tableBodyArray.push(<tr key={`tr${rowsDataIndex}`}>{fieldArray}</tr>);
      }

      //  table footer
      const footerArray = [];
      const footerOutputFields = [];

      if (
        footerRow &&
        typeof tableDefinition.columns !== 'undefined' &&
        tableDefinition.columns.length > 0 &&
        typeof colTotals !== 'undefined'
      ) {
        for (
          let thIndex = 0;
          thIndex < tableDefinition.columns.length;
          thIndex += 1
        ) {
          const currentFooterId = tableDefinition.columns[thIndex];
          const currentColumnDefinition =
            tableDefinition.columnDefinitions[currentFooterId];

          const footerStyle = {
            fontWeight: 'bold',
            marginTop: '8px',
            marginLeft: '0px',
            borderTop: '1px solid black',
            borderBottom: '2px solid black',
          };

          let footerVal;
          if (
            typeof currentColumnDefinition?.totalColumn !== 'undefined' &&
            currentColumnDefinition.totalColumn === true
          ) {
            if (
              typeof currentColumnDefinition.totalField !== 'undefined' &&
              currentColumnDefinition.totalField.length > 0
            ) {
              if (currentColumnDefinition?.valueType === 'percentage') {
                footerVal = colTotals[currentColumnDefinition.totalField] / 100;
              } else {
                footerVal = colTotals[currentColumnDefinition.totalField];
              }
            } else {
              footerVal = Number(colTotals[currentFooterId]);
            }
          }

          let fieldFooterStyle = {};

          if (
            typeof footerVal !== 'undefined' &&
            String(footerVal).trim().length > 0
          ) {
            fieldFooterStyle = footerStyle;
          }

          footerOutputFields.push(
            displayField(
              footerVal,
              currentColumnDefinition.valueType,
              `ft${currentFooterId}`,
              currentColumnDefinition.align,
              fieldFooterStyle,
              false, // headers aren't editable
              null, // no function needed
            ),
          );
        }

        footerArray.push(
          <tfoot key="partstabletotals">
            <tr key="partstotalstr">{footerOutputFields}</tr>
          </tfoot>,
        );
      }

      returnContent = (
        <div
          style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
        >
          <table
            key="partstable"
            style={{
              width: '100%',
            }}
          >
            {tableHeadArray}
            <tbody key="partstablebody">{tableBodyArray}</tbody>
            {footerArray}
          </table>
        </div>
      );

      return returnContent;
    }
    logMessage(
      'displayPartsTable',
      'No rows in parts table (partsTable.rows)',
      partsTable,
      'PricingSection.jsx',
      'ERROR',
    );

    // end table body
  }

  return (
    <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td
              colSpan={3}
              style={{
                padding: '15px',
                backgroundColor: '#666666',
                color: '#FFFFFF',
                textAlign: 'center',
                borderRadius: '5px',
              }}
            >
              <p style={{ color: 'white' }}>
                L O A D I N G&nbsp;&nbsp;&nbsp;&nbsp;P R O D U C
                T&nbsp;&nbsp;&nbsp;&nbsp;D E T A I L S
              </p>
              <div className="loader" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
